export const FormatDate = (date) => {
  const jsDate = new Date(date);

  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  return jsDate.toLocaleTimeString(undefined, options);
};

export const convertDateInFormat = (format, dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const pad = (num) => num.toString().padStart(2, "0");
  return format
    .replace("yyyy", year)
    .replace("mm", pad(month))
    .replace("dd", pad(day));
};

export const formatDateInDMY = (givenDate) => {
  const date = new Date(givenDate);
  const year = date.getFullYear();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let month = date.getMonth();
  const monthInWords = monthNames[month];
  const day = date.getDate();

  month = month + 1;
  const dayWithLeadingZero = String(day).padStart(2, "0");
  const monthWithLeadingZeroes = String(month).padStart(2, "0");

  return {
    dateInWords: `${day} ${monthInWords} ${year}`,
    dateInDMYFormat: `${dayWithLeadingZero}-${monthWithLeadingZeroes}-${year}`,
  };
};

export const formatDateAgo = (date) => {
  const inputDate = new Date(date);
  const currentDate = new Date();
  const inputDateTime = inputDate.getTime();
  const currentDateTime = currentDate.getTime();
  const timeDifference = currentDateTime - inputDateTime;

  const minutesAgo = Math.floor(timeDifference / (1000 * 60));
  const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (minutesAgo < 60) {
    return `${minutesAgo} minute${minutesAgo !== 1 ? "s" : ""} ago`;
  } else if (hoursAgo < 24) {
    return `${hoursAgo} hour${hoursAgo !== 1 ? "s" : ""} ago`;
  } else {
    return `${daysAgo} day${daysAgo !== 1 ? "s" : ""} ago`;
  }
};

export const isGivenTimeMoreThan24hrs = (givenDate) => {
  const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
  const jsDate = new Date(givenDate);
  const currentDate = new Date();
  const timeDifference = currentDate - jsDate;
  return timeDifference >= twentyFourHoursInMilliseconds;
};

export const formatTimeLeft = (targetDate) => {
  const now = new Date();
  const difference = targetDate - now;

  const millisecondsInYear = 1000 * 60 * 60 * 24 * 365;
  const millisecondsInMonth = 1000 * 60 * 60 * 24 * 30;
  const millisecondsInDay = 1000 * 60 * 60 * 24;
  const millisecondsInHour = 1000 * 60 * 60;
  const millisecondsInMinute = 1000 * 60;
  const millisecondsInSecond = 1000;

  if (difference <= 0) {
    return null;
  }

  const years = Math.floor(difference / millisecondsInYear);
  const remainingMonths = Math.floor(
    (difference % millisecondsInYear) / millisecondsInMonth
  );
  const remainingDays = Math.floor(
    (difference % millisecondsInMonth) / millisecondsInDay
  );
  const remainingHours = Math.floor(
    (difference % millisecondsInDay) / millisecondsInHour
  );
  const remainingMinutes = Math.floor(
    (difference % millisecondsInHour) / millisecondsInMinute
  );
  const remainingSeconds = Math.floor(
    (difference % millisecondsInMinute) / millisecondsInSecond
  );

  if (years > 0) {
    if (remainingMonths > 0) {
      return `${years} year${years > 1 ? "s" : ""} ${remainingMonths} month${
        remainingMonths > 1 ? "s" : ""
      } remaining`;
    } else {
      return `${years} year${years > 1 ? "s" : ""} remaining`;
    }
  } else if (remainingMonths > 0) {
    if (remainingDays > 0) {
      return `${remainingMonths} month${
        remainingMonths > 1 ? "s" : ""
      } ${remainingDays} day${remainingDays > 1 ? "s" : ""} remaining`;
    } else {
      return `${remainingMonths} month${
        remainingMonths > 1 ? "s" : ""
      } remaining`;
    }
  } else if (remainingDays > 0) {
    return `${remainingDays} day${remainingDays > 1 ? "s" : ""} remaining`;
  } else if (remainingHours > 0) {
    return `${remainingHours} hour${remainingHours > 1 ? "s" : ""} remaining`;
  } else if (remainingMinutes > 0) {
    return `${remainingMinutes} minute${
      remainingMinutes > 1 ? "s" : ""
    } remaining`;
  } else {
    return `${remainingSeconds} second${
      remainingSeconds > 1 ? "s" : ""
    } remaining`;
  }
};

export const createSlug = (title) => {
  try {
    return title
      ?.toLowerCase()
      .replace("_", " ")
      .replace(/\s+/g, "-") // replace spaces with hyphen
      .replace(/[^\w-]+/g, "-") // replace all non word charactors except hyphen
      .replace(/\-\-+/g, "-") // replace multiple hyphen with a sigle hyphen
      .replace(/^-+/, "") // remove hyphen from start
      .replace(/-+$/, "") // remove last hyphen
      .trim();
  } catch (err) {
    console.log(err);
  }
};

export const themeColorsMap = {
  white: { textColor: "#000", bgColor: "#fff" },
  yellow: { textColor: "#8c4500", bgColor: "#fff2cc" },
  blue: { textColor: "#3f57cb", bgColor: "#dfecff" },
  green: { textColor: "#067519", bgColor: "#e5f8e5" },
  pink: { textColor: "#c600ca", bgColor: "#fff1fe" },
  purple: { textColor: "#4039a8", bgColor: "#f8f1ff" },
  red: { textColor: "#940a00", bgColor: "#ffd1ce" },
  highlightBlue: { textColor: "#fff", bgColor: "#3f57cb" },
  highlightPurple: { textColor: "#fff", bgColor: "#8b3dff" },
};
