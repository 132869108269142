import React from "react";
import ListingBCardView from "./ListingBCard.view";
import Link from "next/link";
import { themeColorsMap } from "@/outscal-commons-frontend/utils/commons";
import UserCourseService from "@/Logic/ClientLogic/UserCourseService/UserCourse.service";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import { appendAsQueryParam, removeHashFromUrl } from "@/utils/urlHelper";
import { useRouter } from "next/router";

const ListingBCard = ({
  analyticsName = "",
  title = "",
  heading = "",
  subHeading = "",
  image = {},
  ctaText = "",
  ctaLink = "",
  uspText = "",
  tags = [],
  imageQuality = 80,
  theme = "white",
}) => {
  const router = useRouter();
  const isThemeVertical = theme.includes("vertical");
  const color = isThemeVertical ? theme.split("-")?.[1] : theme;
  const { user } = useUserActions();
  const isUserEnrolledCurrCourse =
    UserCourseService.instance?.checkUserEnrolledInCurrCourse(user);

  return (
    !isUserEnrolledCurrCourse && (
      <Link
        href={`${removeHashFromUrl(router.asPath)}${appendAsQueryParam(
          router.asPath,
          ctaLink
        )}`}
        id={analyticsName}
        rel="nofollow"
        style={{ width: "100%" }}
      >
        <ListingBCardView
          title={title}
          analyticsName={analyticsName}
          viewAnalyticsName={`${analyticsName}-view`}
          heading={heading}
          plugImage={image?.url}
          subHeading={subHeading}
          ctaText={ctaText}
          theme={theme}
          textColor={themeColorsMap[color]?.textColor}
          bgColor={themeColorsMap[color]?.bgColor}
          uspText={uspText}
          tags={tags}
          imageQuality={imageQuality}
        />
      </Link>
    )
  );
};

export default ListingBCard;
