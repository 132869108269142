import React from "react";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import Image from "next/image";
import styles from "./ListingBCard.module.css";

const ListingBCardView = ({
  analyticsName,
  viewAnalyticsName,
  heading,
  subHeading,
  plugImage,
  imageQuality,
  ctaText,
  textColor,
  bgColor,
  title,
  uspText,
  tags,
  theme,
}) => {
  const isVertical = theme?.includes("vertical");

  return (
    <div
      id={viewAnalyticsName}
      className={`${styles.root} ${isVertical ? styles.verticalLayout : ""}`}
      style={{ "--bgColor": bgColor, "--textColor": textColor }}
      data-analytics={[UIElements.CARD, analyticsName]}
      view-analytics={[UIElements.CARD, viewAnalyticsName]}
      rel="nofollow"
    >
      <div className={styles.detailsWrap}>
        {isVertical && (
          <button
            className={styles.cta}
            style={{ backgroundColor: bgColor, color: "#fff" }}
            data-analytics={[UIElements.BUTTON, `${analyticsName}-cta`]}
          >
            {ctaText}
          </button>
        )}

        {title && <p className={styles.title}>{title}</p>}

        {plugImage && (
          <div style={{ position: "relative" }}>
            {uspText && (
              <div
                className={styles.uspText}
                style={{ "--textColor": textColor }}
              >
                {uspText}
              </div>
            )}
            <Image
              className={styles.image}
              src={plugImage}
              alt={analyticsName}
              width={120}
              height={120}
              quality={imageQuality}
            />
          </div>
        )}

        <div className={styles.body}>
          <div className={styles.header}>
            <p
              className={`${styles.heading} ${styles["trunc-1"]}`}
              style={{ "--textColor": textColor }}
            >
              {heading}
            </p>
            {subHeading && (
              <p
                className={`${styles.subHeading} ${styles["trunc-1"]}`}
                style={{ color: "#6c6c6c" }}
              >
                {subHeading}
              </p>
            )}
            {tags?.length > 0 && (
              <div className={styles.tags}>
                {tags.map((item) => (
                  <div
                    className={styles.tag}
                    key={item}
                    style={{ "--textColor": textColor }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>

          {!isVertical && (
            <button
              className={styles.cta}
              style={{ backgroundColor: bgColor, color: "#fff" }}
              data-analytics={[UIElements.BUTTON, `${analyticsName}-cta`]}
            >
              {ctaText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListingBCardView;
